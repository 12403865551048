import React, { ReactNode } from 'react';

interface BackgroundImageProps {
  children: ReactNode;
  backgroundImage: string;
  style?: React.CSSProperties;
}
const BackgroundImage = ({ children, backgroundImage, style: _style }: BackgroundImageProps): any => {
  // 設置背景圖片的樣式
  const style = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',  // 這會使圖片填充容器，同時保持它的寬高比
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',  // 使容器的寬度與螢幕的寬度相匹配
    height: 'auto',  // 根據圖片的寬高比自動設置高度
    ..._style
  };

  return <div style={style} className='container'>
    {children}
  </div>
};


export default BackgroundImage;
