import React, { ReactNode } from 'react';

interface ModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  title: string;
  content: ReactNode;
}

const Modal = ({ isOpen, onConfirm, title, content }: ModalProps) => {
  if (!isOpen) return null;


  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 overflow-y-auto h-full w-full z-40" id="my-modal">
      <div className="relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[fit-content]">
        <div className="bg-white rounded-lg shadow-lg px-[30px] py-[20px] flex justify-center items-center flex-col">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
          <div className="mt-2 text-center">
            <p className="text-sm text-gray-500">{content}</p>
          </div>
          <div className="mt-4">
            <button
              type="button"
              className="inline-flex justify-center px-2 py-1 text-md font-medium text-black bg-white border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              onClick={handleConfirm}
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
