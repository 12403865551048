import {useState} from "react"
import Botton from "./Button"
import { useRouter } from '@/hooks/useLocation';
import { useLocation } from 'react-router-dom';
import { TCoupon } from "./types"

const textStyle = {
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '26px',
  letterSpacing: '1px',
  // textAlign: 'center'
};

const titleStyle = {
  fontFamily: 'Inter',
  fontSize: '24px',
  fontWeight: 800,
  lineHeight: '24px',
  letterSpacing: '1px',
  // textAlign: 'center'
};

const descStyle = {
  fontFamily: 'Inter',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '20px',

  // textAlign: 'center'
};



const CouponCard = ({coupon, active, setCoupon}: {coupon: TCoupon, active: boolean, setCoupon: React.Dispatch<React.SetStateAction<TCoupon|null>>}) => {
  const {title, desc, redeem, expire, imgSrc} = coupon
  const location = useRouter()

  return <div>
      <div className="flex flex-col w-full justify-between">
      <div className="pb-[14px] w-[230px]" style={titleStyle}>{title}</div>
      <div style={descStyle}>{desc}</div>
      {active && <div className="pb-[20px]" style={descStyle}>有效日期：{expire}</div>}
      {active || <div className="pb-[20px]" style={descStyle}>兌換日期：{redeem}</div>}
      <img
        src={imgSrc}
        width="100%"
        alt="photo_gift1"
      />
    </div>
    {active && <div className="pt-[20px] ">
      <Botton text="我要兌換" onClick={async ()=> {
        await setCoupon(coupon)
        location.push("/coupon/detail")
      }}></Botton>
    </div>}
  </div>
}

const Coupon = ({setCoupon, couponList, loading}: {setCoupon: React.Dispatch<React.SetStateAction<TCoupon|null>>, couponList: TCoupon[], loading: boolean}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const someParam = queryParams.get('mode'); // 假設 URL 是 /your-path?someParam=value
  const [active, setActive] = useState(someParam!== "redeem");

  return <div
    className="bg-black w-full min-h-full flex flex-col items-center pt-[30px] pb-8"
  >
    <img className="absolute top-[0px]" src="/images/coupon-background.png" alt="coupon-backgroud" />
    <div className="flex z-10 flex-row justify-between items-end w-full px-3 pb-8">
      <div></div>
      <img
        alt="coupon-list"
        src="/images/coupon-list.png"
        width={88}
      />
      <div></div>
    </div>
    
    <div className="p-[1px] bg-white" style={{
      width: "calc(100% - 40px)",
      borderRadius: "8px",
      background: "linear-gradient(to bottom, white 0%, rgba(0, 0, 0, 0) 100%)"
    }} >
      <div className="bg-black" style={{borderRadius: "8px"}}>
        <div className="flex flex-row justify-around" style={{translate: "0px -1px"}}>
          <div
            onClick={() => setActive(true)}
            className={`flex justify-center flex-1 py-[10px] ${active? "border-r": "border-b"}`}
            style={{
              ...textStyle,
              ...active? {
                background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, transparent 70%)",
                borderTopRightRadius: "8px"
              }: {}
            }}>
              可兌換
          </div>
          <div
            onClick={() => setActive(false)}
            className={`flex justify-center flex-1 py-[10px] ${active? "border-b": "border-l"}`}
            style={{
              ...textStyle,
              ...!active? {
                background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, transparent 70%)",
                borderTopLeftRadius: "8px"
              }: {}
            }}>
              已兌換
          </div>
        </div>
        <div className="px-[20px] py-[24px]">
          {
            couponList
              .filter(item => {
                if(active) return item.redeem === null
                return item.redeem !== null
              })
              .map((item, index) => {
                return <>
                  <CouponCard  key={index} coupon={item} active={active} setCoupon={setCoupon} />
                  {(index !== couponList.filter((c) => {
                    if(active) return c.redeem === null
                    return c.redeem !== null
                  }).length - 1) &&<div className="h-[1px] bg-white w-full my-[30px]"></div>}
                </>
              })
          }
          {
            couponList.filter((c) => {
              if(active) return c.redeem === null
              return c.redeem !== null
            }).length === 0 && !loading && <div className="w-full flex items-center justify-center pt-[20px]">
              目前尚無任何兌換券
            </div>
          }
        </div>
      </div>
    </div>
  </div>
}

export default Coupon