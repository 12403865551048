import BackgroundImage from "@/components/BackgroundImage"
import Points from "./page"


export const PointsRouters = () => {
  return (
      <BackgroundImage
        backgroundImage="/images/point-background-black.png" 
      >
        <Points/>
      </BackgroundImage>
  );
}