import ReactGA from 'react-ga4';

type GtagEvent = 
  | 'Btn_Started' | 'Page_Intro' | 'Btn_Go' | 'Page_Homepage' | 'Btn_Welcome'
  | 'Page_Welcome' | 'Page_Stage_1' | 'Btn_Stage_1_A' | 'Btn_Stage_1_B'
  | 'Btn_Stage_1_C' | 'Btn_Stage_1_D' | 'Btn_Stage_1_E' | 'Btn_Stage_1_Summit'
  | 'Btn_Stage_1_Play' | 'Btn_Stage_1_Next' | 'Page_Stage_2' | 'Btn_Stage_2_A'
  | 'Btn_Stage_2_B' | 'Btn_Stage_2_C' | 'Btn_Stage_2_D' | 'Btn_Stage_2_E'
  | 'Btn_Stage_2_F' | 'Btn_Stage_2_G' | 'Btn_Stage_2_Summit' | 'Btn_Stage_2_Play'
  | 'Btn_Stage_2_Next' | 'Page_Stage_3' | 'Btn_Stage_3_A' | 'Btn_Stage_3_B'
  | 'Btn_Stage_3_C' | 'Btn_Stage_3_D' | 'Btn_Stage_3_E' | 'Btn_Stage_3_F'
  | 'Btn_Stage_3_G' | 'Btn_Stage_3_H' | 'Btn_Stage_3_Summit' | 'Btn_Stage_3_Play'
  | 'Btn_Stage_3_Next' | 'Page_Script' | 'Btn_Script_RandomName' | 'Btn_Script_Summit'
  | 'Page_Soul' | 'Btn_Soul_A' | 'Btn_Soul_B' | 'Btn_Soul_C' | 'Btn_Soul_Summit'
  | 'Btn_Ending_Next' | 'Btn_Ending_Summit' | 'Btn_Send_Invitation'
  | 'Page_Result_Best_Director' | 'Page_Result_Best Art Direction'
  | 'Page_Result_Best New Performer' | 'Page_Result_Best Film Editing'
  | 'Page_Result_Best_Narrative_Feature' | 'Page_Result_Best New Director'
  | 'Page_Result_Best Original Screenplay' | 'Page_Result_Best Animated Feature'
  | 'Page_Result_Best Animated Short Film' | 'Page_Result_Best Original Film Score'
  | 'Page_Result_Best Supporting Actor' | 'Page_Result_Best Leading Actor'
  | 'Page_Result_Best Leading Actress' | 'Page_Result_Best Supporting Actress'
  | 'Page_Result_Best Makeup & Costume Design' | 'Page_Result_Best Original Film Song'
  | 'Page_Result_Best Sound Effects' | 'Page_Result_Best Visual Effects'
  | 'Page_Result_Best Adapted Screenplay' | 'Page_Result_Best Live Action Short Film'
  | 'Page_Result_Best Action Choreography' | 'Page_Result_Best Cinematography'
  | 'Page_Result_Best Documentary Feature' | 'Page_Result_Best Documentary Short Film'
  | 'Page_Easter_Egg' | 'Btn_Easter_Egg_How' | 'Btn_Easter_Egg_Tips';

  const nameToEvent: Record<string, GtagEvent> = {
    "吳念真": "Btn_Stage_1_A",
    "王淨": "Btn_Stage_1_B",
    "楊貴媚": "Btn_Stage_1_C",
    "許光漢": "Btn_Stage_1_D",
    "宋芸樺": "Btn_Stage_1_E",
    "劉冠廷": "Btn_Stage_2_A",
    "朱軒洋": "Btn_Stage_2_B",
    "賈靜雯": "Btn_Stage_2_C",
    "林柏宏": "Btn_Stage_2_D",
    "莫子儀": "Btn_Stage_2_E",
    "張震": "Btn_Stage_2_F",
    "曾敬驊": "Btn_Stage_2_G",
    "謝盈萱": "Btn_Stage_3_A",
    "柯震東": "Btn_Stage_3_B",
    "陳淑芳": "Btn_Stage_3_C",
    "吳慷仁": "Btn_Stage_3_D",
    "桂綸鎂": "Btn_Stage_3_E",
    "李康生": "Btn_Stage_3_F",
    "張艾嘉": "Btn_Stage_3_G",
    "阮經天": "Btn_Stage_3_H",
    "最佳導演": "Page_Result_Best_Director",
    "最佳美術設計": "Page_Result_Best Art Direction",
    "最佳新演員": "Page_Result_Best New Performer",
    "最佳剪輯": "Page_Result_Best Film Editing",
    "最佳劇情片": "Page_Result_Best_Narrative_Feature",
    "最佳新導演": "Page_Result_Best New Director",
    "最佳原著劇本": "Page_Result_Best Original Screenplay",
    "最佳動畫片": "Page_Result_Best Animated Feature",
    "最佳動畫短片": "Page_Result_Best Animated Short Film",
    "最佳原創電影音樂": "Page_Result_Best Original Film Score",
    "最佳男配角": "Page_Result_Best Supporting Actor",
    "最佳男主角": "Page_Result_Best Leading Actor",
    "最佳女主角": "Page_Result_Best Leading Actress",
    "最佳女配角": "Page_Result_Best Supporting Actress",
    "最佳造型設計": "Page_Result_Best Makeup & Costume Design",
    "最佳原創電影歌曲": "Page_Result_Best Original Film Song",
    "最佳音效": "Page_Result_Best Sound Effects",
    "最佳視覺效果": "Page_Result_Best Visual Effects",
    "最佳改編劇本": "Page_Result_Best Adapted Screenplay",
    "最佳劇情短片": "Page_Result_Best Live Action Short Film",
    "最佳動作設計": "Page_Result_Best Action Choreography",
    "最佳攝影": "Page_Result_Best Cinematography",
    "最佳紀錄片": "Page_Result_Best Documentary Feature",
    "最佳紀錄短片": "Page_Result_Best Documentary Short Film",
  }

// This function sends events to Google Analytics
export const trackEvent = (event: string | GtagEvent) => {
  ReactGA.event(event);
};

export const trackEventWithName = (name: string) => {
  trackEvent(nameToEvent[name])
};
