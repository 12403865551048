'use client'

import React, {useState, useEffect} from "react";
import AnimatedButton from "@/components/AnimatedButton"
import FadedFragment  from "@/components/FadedFragment"
import { useRouter } from '@/hooks/useLocation';
import { useSpring, animated } from 'react-spring';
import { easeCubicInOut } from 'd3-ease';
import Image from "@/components/Image"
import useAudioPlayer from "@/hooks/useAudioPlayer";
import { useGlobalContext } from "@/app/context/store";
import { ending } from "@common/data"
import { trackEvent } from "@/common/gaUtil";

const useSerialAnimation = () => {
	const textLine1 = useSpring({
		from: { opacity: 0, transform: "translateY(1em)" },
		to: { opacity: 1, transform: "translateY(0)" },
		delay: ending.subtitleTimeline[0],
		config: { duration: 300, easing: easeCubicInOut }
	});

	const textLine2 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: ending.subtitleTimeline[1],
			config: { duration: 300, easing: easeCubicInOut }
	});

	const textLine3 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: ending.subtitleTimeline[2],
			config: { duration: 300, easing: easeCubicInOut }
	});

	const textLine4 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: ending.subtitleTimeline[3],
			config: { duration: 300, easing: easeCubicInOut }
	});

	const textLine5 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: ending.subtitleTimeline[4],
			config: { duration: 300, easing: easeCubicInOut }
	});

	const textLine6 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: ending.subtitleTimeline[5],
			config: { duration: 300, easing: easeCubicInOut }
	});

  const textLine7 = useSpring({
		from: { opacity: 0, transform: "translateY(1em)" },
		to: { opacity: 1, transform: "translateY(0)" },
		delay: ending.subtitleTimeline[6],
		config: { duration: 300, easing: easeCubicInOut }
	});

	const textLine8 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: ending.subtitleTimeline[7],
			config: { duration: 300, easing: easeCubicInOut }
	});

	const textLine9 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: ending.subtitleTimeline[8],
			config: { duration: 300, easing: easeCubicInOut }
	});

	const textLine10 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: ending.subtitleTimeline[9],
			config: { duration: 300, easing: easeCubicInOut }
	});

	const textLine11 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: ending.subtitleTimeline[10],
			config: { duration: 300, easing: easeCubicInOut }
	});

	const textLine12 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: ending.subtitleTimeline[11],
			config: { duration: 300, easing: easeCubicInOut }
	});

  const textLine13 = useSpring({
    from: { opacity: 0, transform: "translateY(1em)" },
    to: { opacity: 1, transform: "translateY(0)" },
    delay: ending.subtitleTimeline[12],
    config: { duration: 300, easing: easeCubicInOut }
});
	return [textLine1, textLine2, textLine3, textLine4, textLine5, textLine6, textLine7, textLine8, textLine9, textLine10, textLine11, textLine12, textLine13]
}



export default function Page() {
  const textAnimations= useSerialAnimation()
  const router = useRouter();
  const [fading, setFading] = useState(false);
	const {setBgPlaying} = useGlobalContext()

  const audio = useAudioPlayer(
		{
		 src: "/audio/李安_MIX.m4a",
		 onFinish: () => setBgPlaying(true)
		}
	 )

  useEffect(() => {
    audio.play()
  }, [])

  const textAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 500, easing: easeCubicInOut },
  });

  const photoAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0px)' },
    config: { duration: 500, easing: easeCubicInOut },
  });

  const btnAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 400, easing: easeCubicInOut },
    delay: 500
  });

  return (
      <div className="flex pt-5  items-center flex-col h-full pb-40">
        <Image
          loading="eager"
          layout="intrinsic"
          width={30}
          height={0}
          src="/images/logo2.png"
          alt=""
        />
        <FadedFragment
          className="flex flex-col flex-grow justify-between items-center pt-6"
          fading={fading}
          onNextPage={() => router.push("/pages/award")}
        >
          <div>
            <animated.div style={photoAnimation} className="flex flex-col items-center">
              <Image
                className="z-10 absolute left-0 w-[100vw]"
                loading="eager"
                width={0}
                height={78}
                layout="responsive"
                src="/images/wave2.png"
                style={{
                  height: "auto",
                  minWidth: "100vw",
                  top: "11px",
                  left: "unset",
                  position: "fixed"
              }}
                alt=""
              />  
              <Image
                className="pb-2 z-20"
                loading="eager"
                layout="intrinsic"
                width={100}
                height={0}
                src="/images/photo_李安.png"
                alt=""
              />
              <div className="pb-5">李安</div>
            </animated.div>
            <div className="text-center">
              {ending.subtitle.map((line, index) => (
                <animated.div key={index} style={textAnimations[index]}>
                  {line}
                  <br />
                </animated.div>
              ))}
            </div>
          </div>
          <AnimatedButton text="入圍揭曉" style={{...btnAnimation, width: "180px"}} onClick={() =>{
            setFading(true);
            audio.pause();
            setBgPlaying(false);
            trackEvent('Btn_Ending_Summit')
          }}/>
        </FadedFragment>
      </div>
  );
}
