'use client'

import { useEffect, useState } from "react";
import { useSpring, animated } from 'react-spring';
import FadedFragment from "@/components/FadedFragment"
import { easeCubicInOut } from 'd3-ease';
import { useRouter } from '@/hooks/useLocation';
import BrandComponent from "@/components/BrandComponent";
import useAudioPlayer from "@/hooks/useAudioPlayer"
import { useGlobalContext } from "@/app/context/store"
import Image from "@/components/Image"
import ToggleImageButton from "@/components/ToggleImageButton";
import { trackEvent } from "@/common/gaUtil";


const titleStyle = {
  color: 'var(--main_color_red, #F92C16)',
  textAlign: 'center' as const,
  fontFamily: 'Inter',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '160%',
  letterSpacing: '0.45px'
};

const textStyle = {
  ...titleStyle,
  color: '#FFF',
};

const AudioControl = ({ onFinish, actor, stage, setReplay }: any) => {

  const audioPlayer = useAudioPlayer({
    src: `/audio/${actor}_MIX.m4a`,
    autoPlay: true,
    onFinish: () => {
      onFinish()
    }
  });

  return (
    <div className="z-10 h-[42px]">
      <ToggleImageButton
        width={42}
        height={42}
        defaultImage="/images/audio-replay.png"
        activeImage="/images/audio-replay-active.png"
        onClick={() => {
          trackEvent(`Btn_Stage_${stage + 1}_Play`);
          audioPlayer.replay()
          setReplay(true)
        }}
      />
    </div>
  );
};

const useSerialAnimation = (subtitleTimeline: number[]) => {
  const textLine0 = useSpring({
    from: { opacity: 0, transform: "translateY(1em)" },
    to: { opacity: 1, transform: "translateY(0)" },
    delay: subtitleTimeline[0],
    config: { duration: 200 * 0 + 300, easing: easeCubicInOut }
  });

  const textLine1 = useSpring({
    from: { opacity: 0, transform: "translateY(1em)" },
    to: { opacity: 1, transform: "translateY(0)" },
    delay: subtitleTimeline[1],
    config: { duration: 200 * 1 + 300, easing: easeCubicInOut }
  });

  const textLine2 = useSpring({
    from: { opacity: 0, transform: "translateY(1em)" },
    to: { opacity: 1, transform: "translateY(0)" },
    delay: subtitleTimeline[2],
    config: { duration: 200 * 2 + 300, easing: easeCubicInOut }
  });

  const textLine3 = useSpring({
    from: { opacity: 0, transform: "translateY(1em)" },
    to: { opacity: 1, transform: "translateY(0)" },
    delay: subtitleTimeline[3],
    config: { duration: 200 * 3 + 300, easing: easeCubicInOut }
  });

  const textLine4 = useSpring({
    from: { opacity: 0, transform: "translateY(1em)" },
    to: { opacity: 1, transform: "translateY(0)" },
    delay: subtitleTimeline[4],
    config: { duration: 200 * 4 + 300, easing: easeCubicInOut }
  });

  const textLine5 = useSpring({
    from: { opacity: 0, transform: "translateY(1em)" },
    to: { opacity: 1, transform: "translateY(0)" },
    delay: subtitleTimeline[5],
    config: { duration: 200 * 5 + 300, easing: easeCubicInOut }
  });

  const textLine6 = useSpring({
    from: { opacity: 0, transform: "translateY(1em)" },
    to: { opacity: 1, transform: "translateY(0)" },
    delay: subtitleTimeline[6],
    config: { duration: 200 * 6 + 300, easing: easeCubicInOut }
  });

  const textLine7 = useSpring({
    from: { opacity: 0, transform: "translateY(1em)" },
    to: { opacity: 1, transform: "translateY(0)" },
    delay: subtitleTimeline[7],
    config: { duration: 200 * 7 + 300, easing: easeCubicInOut }
  });
  const textLine8 = useSpring({
    from: { opacity: 0, transform: "translateY(1em)" },
    to: { opacity: 1, transform: "translateY(0)" },
    delay: subtitleTimeline[8],
    config: { duration: 200 * 8 + 300, easing: easeCubicInOut }
  });
  const textLine9 = useSpring({
    from: { opacity: 0, transform: "translateY(1em)" },
    to: { opacity: 1, transform: "translateY(0)" },
    delay: subtitleTimeline[9],
    config: { duration: 200 * 9 + 3000, easing: easeCubicInOut }
  });
  return [textLine0, textLine1, textLine2, textLine3, textLine4, textLine5, textLine6, textLine7, textLine8, textLine9]
}

const AnimatedLines = ({ actor }: any) => {
  const textAnimations = useSerialAnimation(actor?.subtitleTimeline ?? [])
  const textLines = actor?.subtitle ?? []
  return <div className="text-center" style={textStyle}>
    {textLines.map((line: string, index: number) => (
      <animated.div key={index} style={textAnimations[index]}>
        {line}
        <br />
      </animated.div>
    ))}
  </div>
}

const CannotSeeLines = ({ actor }: any) => {
  const textLines = actor?.subtitle ?? []
  return <div className="text-center" style={{ ...textStyle, opacity: 0 }}>
    {textLines.map((line: string, index: number) => (
      <div key={index}>
        {line}
        <br />
      </div>
    ))}
  </div>
}


const StoryComponent = () => {
  const router = useRouter();
  const { stage, setBgPlaying, firstSelected, secondSelected, thirdSelected } = useGlobalContext();
  const [fading, setFading] = useState(false);
  const [replay, setReplay] = useState(false);
  const [audioFading, setAudioFading] = useState(false);
  const actor = [firstSelected, secondSelected, thirdSelected][stage]

  useEffect(() => {
    replay && setReplay(false)
  }, [replay])

  if (actor === null) return null;

  return (
    actor && <div className="flex flex-col w-100dvw h-full pt-5 flex-grow justify-between items-center pb-50">
      <BrandComponent active={stage} />
      <FadedFragment
        fading={fading}
        onNextPage={() => {
          router.push("/pages/questions3")
        }}
      >
        <div></div>
        <div className="flex flex-col gap-10px">
          <div className=" flex flex-col items-center mb-[17px]">
            <Image
              loading="eager"
              layout="fixed"
              className="mb-2"
              width={100}
              height={100}
              src={`/images/photo_${actor.name}.png`}
              alt=""
            />
            <div>
              {actor.name}
            </div>
          </div>
          <div style={titleStyle}>
            △ {actor.title[0]}<br />{actor.title[1]}
          </div>

          {replay ? <CannotSeeLines actor={actor} /> : <AnimatedLines actor={actor} />}
        </div>
      </FadedFragment>
      <FadedFragment
        fading={audioFading}
        onNextPage={() => {
          router.push("/pages/questions4")
        }}
      >
        <div style={{
          height: "76px",
          display: "flex",
          alignItems: "center"
        }}>
          <AudioControl
            setReplay={setReplay}
            actor={actor.name}
            stage={stage}
            onFinish={() => {
              console.log("AudioControl finished")
              setBgPlaying(true)
              if (stage === 0 || stage === 1) {
                setFading(true)
                console.log("AudioControl finished2")

              } else {
                setAudioFading(true)
                console.log("AudioControl finishe3d")

              }
            }}
          />
        </div>
      </FadedFragment>
      <div className="absolute bottom-40 left-0 w-full">
        <Image
          src="/images/wave2.png"
          alt="wave2"
          loading="eager"
          width={0}
          // className="absolute bottom-40 left-0"
          height={78}
          layout="responsive"
          style={{
            height: "auto",
            width: "100vw"
          }}
        />
      </div>
    </div>
  );
};

export default StoryComponent;
