'use client'

import React, { useState } from "react";
import { useSpring, animated } from 'react-spring';
import { easeCubicInOut } from 'd3-ease';
import { useRouter } from '@/hooks/useLocation';
import ToggleImageButton from "@/components/ToggleImageButton";
import { useGlobalContext } from "@/app/context/store"
import BrandComponent from "@/components/BrandComponent"
import FadedFragment from "@/components/FadedFragment"
import Image from "@/components/Image"
import { trackEvent } from "@/common/gaUtil";


const textStyle = {
  color: '#FFF',
  textAlign: 'center' as const,
  fontFamily: 'Inter',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '160%',
  letterSpacing: '0.45px'
};


const titleStyle = {
  color: 'var(--main_color_red, #F92C16)',
  textAlign: 'center' as const,
  fontFamily: 'Inter',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '160%',
  letterSpacing: '0.45px'
};


const AudioControl = ({ setFading, stage }: { stage: number, setFading: any }) => {
  const router = useRouter();

  const btnAnimations = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 400, easing: easeCubicInOut },
  })

  return (
    <animated.div className="flex gap-5 items-center justify-center h-[76px] z-10" style={btnAnimations}>
      <ToggleImageButton
        width={42}
        height={42}
        defaultImage="/images/audio-replay.png"
        activeImage="/images/audio-replay-active.png"
        onClick={() => {
          setFading();
          trackEvent(`Btn_Stage_${stage + 1}_Play`);
          setTimeout(() => {
            router.push('/pages/questions2')
          }, 300)
        }}
      />
      <animated.img
        onClick={
          () => {
            setFading();
            trackEvent('Btn_Stage_3_Next');
            setTimeout(() => {
              router.push('/pages/questions5')
            }, 300)
          }
        }
        width={76}
        height={76}
        alt="earphone"
        loading="eager"
        srcSet="/images/btn-cut.png"
        className="object-cover object-left shrink-0 active:bg-gray-300 rounded-[50px]"
      />
    </animated.div>
  )
}

const StoryComponent = () => {
  const { stage, firstSelected, secondSelected, thirdSelected } = useGlobalContext();
  const [fading, setFading] = useState(false);
  const actor = [firstSelected, secondSelected, thirdSelected][stage]

  const textLines = actor?.subtitle ?? [""]




  return (
    actor && <div className="flex flex-col w-100dvw h-full pt-5">
      <BrandComponent active={stage} />
      <div
        className="flex flex-grow flex-col justify-between items-center pb-50"
      >
        <div></div>
        <div className="flex flex-col gap-10px">
          <div className=" flex flex-col items-center mb-[17px]">
            <Image
              loading="eager"
              layout="fixed"
              className="mb-2"
              width={100}
              height={100}
              src={`/images/photo_${actor.name}.png`}
              alt=""
            />
            <div>
              {actor.name}
            </div>
          </div>
          <FadedFragment
            fading={fading}
            onNextPage={() => { }}>
            <div style={titleStyle} className="pb-[10px]">
              △ {actor.title[0]}<br />{actor.title[1]}
            </div>
            <div className="text-center" style={textStyle}>
              {textLines.map((line: string, index: number) => (
                <div key={index}>
                  {line}
                  <br />
                </div>
              ))}
            </div>
          </FadedFragment>
        </div>
        <FadedFragment
          className="z-10 h-[76px]"
          fading={fading}
          onNextPage={() => { }}>
          <AudioControl key="pg4" stage={stage} setFading={() => setFading(true)} />
        </FadedFragment>
      </div>
      <Image
        src="/images/wave2.png"
        alt="wave2"
        className="absolute  bottom-40 left-0"
        loading="eager"
        width={0}
        height={78}
        layout="responsive"
        style={{
          height: "auto",
          width: "100vw"
        }}
      />
    </div>
  );
};

export default StoryComponent