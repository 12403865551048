'use client'

import React, {useState} from "react";
import { useSpring, animated } from 'react-spring';
import { easeCubicInOut } from 'd3-ease';
import { useRouter } from '@/hooks/useLocation';
import AnimatedButton from "@/components/AnimatedButton";
import ToggleImageButton from "@/components/ToggleImageButton";
import { useGlobalContext } from "@/app/context/store"
import BrandComponent from "@/components/BrandComponent"
import FadedFragment  from "@/components/FadedFragment"
import Image from "@/components/Image"
import { trackEvent } from "@/common/gaUtil";


const textStyle = {
  color: '#FFF',
  textAlign: 'center' as const,
  fontFamily: 'Inter',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '160%', 
  letterSpacing: '0.45px'
};

const AudioControl = ({setFading} : { setFading: any}) => {
  const { stage } = useGlobalContext();  
  const router = useRouter();

  const btnAnimations = useSpring({
    from: { opacity: 0},
    to: { opacity: 1},
    config: { duration: 400, easing: easeCubicInOut },
  })

  if(stage <2) {
    return (
      <div className="flex gap-5  z-10" >
        <ToggleImageButton
          width={42}
          height={42}
          defaultImage="/images/audio-replay.png"
          activeImage="/images/audio-replay-active.png"
          onClick={() => {
            trackEvent(`Btn_Stage_${stage + 1}_Play`);
            setFading();
            setTimeout(() => {
              router.push('/pages/questions2')
            }, 300)
          }}
        />
      </div>
    )
  } else {
    return (
      <animated.div className="flex gap-5 items-center justify-center  z-10" style={btnAnimations}>
        <ToggleImageButton
          width={42}
          height={42}
          defaultImage="/images/audio-replay.png"
          activeImage="/images/audio-replay-active.png"
          onClick={() => {
            trackEvent(`Btn_Stage_${stage + 1}_Play`);
            setFading();
            setTimeout(() => {
              router.push('/pages/questions2')
            }, 300)
          }}
        />
        <animated.img 
          onClick={
            () => {
              setFading();
              setTimeout(() => {
                router.push('/pages/end')
              }, 300)
            }
          }
          width={76}
          height={76}
          alt="earphone"
          loading="eager"
          srcSet="/images/btn-cut.png"
          className="object-cover object-left shrink-0 active:bg-gray-300 rounded-[50px]"
        />
      </animated.div>
    )
  }
}

function StoryComponent() {
  const { stage, setStage } = useGlobalContext();
  const [fading, setFading] = useState(false);
  const [btnFading, setBtnFading] = useState(false)
  const router = useRouter();

  let textLines: string[] = [""]
  if(stage === 0) {
    textLines = ["……接著，有誰出現了？"]
  }
  if(stage === 1) {
    textLines = [".......他們在做什麼？"]
  }
	const animation = useSpring({
    from: { opacity: 0},
    to: { opacity: 1},
    delay: 0, // 淡入间隔 0.4 秒
    config: { duration: 300, easing: easeCubicInOut }
  })


  return (
    <div className="flex flex-col w-100dvw h-full pt-5 flex-grow justify-between items-center pb-50">
      <BrandComponent active={stage}/>
      <FadedFragment
        fading={fading}
        onNextPage={() => {}}
      >
        <div></div>
        <div>
          <div className="flex justify-center item-center pb-5" style={textStyle}>
              {textLines.map((line, index) => (
                <animated.div key={index} style={animation} className="flex text-center justify-center items-center">
                  {line}
                  <br />
                </animated.div>
              ))}
          </div>
          {stage <2 && <AnimatedButton
            onClick={() => {
              setFading(true);
              setBtnFading(true)
              setTimeout(() => {
                trackEvent(`Btn_Stage_${stage + 1}_Next`);
                setStage(stage+1);
                router.push('/pages/questions')
              }, 300)
            }}
            text={stage === 0? "選擇角色" : "選擇事件"}
            showArrow
            fadein={false}
          />}
        </div>
        </FadedFragment >
        <FadedFragment
          fading={btnFading}
          onNextPage={() => {}}
        >
          <div style={{
            height: "76px",
            display: "flex",
            alignItems: "center"
          }}>
            <AudioControl key="pg3" setFading={()=> {
              setFading(true)
            }}/>
          </div>
          <Image
            src="/images/wave2.png"
            alt="wave2"
            className="absolute  bottom-40 left-0"
            loading="eager"
            width={0}
            height={78}
            layout="responsive"
            style={{
                height: "auto",
                width: "100vw"
            }}
          />
        </FadedFragment>
    </div>
  );
}

export default StoryComponent;