import {useState} from "react"
import Botton from "./Button"
import { useRouter } from '@/hooks/useLocation';
import { TCoupon } from "./types"
import { updateUser } from "@common/firebaseDB"
import { useGlobalContext } from "../context/store";

const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  const day = String(today.getDate()).padStart(2, '0');
  
  return `${year}/${month}/${day}`;
}

function encodeToBase64(data: string) {
  return btoa(unescape(encodeURIComponent(data)));
}
const ans = "NjA2MA=="

const textStyle = {
  fontFamily: 'Inter',
  fontSize: '13px',
  fontWeight: 500,
  lineHeight: '16px',
};


const Redeem = ({couponList, coupon, setReload}: { couponList:TCoupon[] ,coupon: TCoupon|null, setReload: React.Dispatch<React.SetStateAction<boolean>>}) => {
  const location = useRouter()
  const [inputValue, setInputValue] = useState('');
  const { userId } = useGlobalContext();

  const updateCoupon = async () => {
    if(coupon === null || userId === null) return
    coupon.redeem = getCurrentDate()
    const newCouponList = couponList.map((item) => {
      if(item.title === coupon.title) return coupon
      else return item
    }).map((item) => { return {
      title: item.title,
      expire: item.expire,
      redeem: item.redeem,
    } })
    console.log({newCouponList})
    await updateUser(userId, {coupons: newCouponList})
  }

  return <div className="bg-black w-full h-full flex flex-col items-center justify-center">
    <div className="w-full px-[40px] text-center">
      <input
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="請點擊文字框輸入"
        type="text"
        className="w-full border-[4px] border-red-500 h-[70px] bg-black text-center focus:outline-none"
      />
      <div style={textStyle} className="mb-[40px] mt-[10px]">! 此欄由工作人員輸入兌換確認碼 !</div>
      <Botton text="送出" onClick={async ()=> {
        const enc = encodeToBase64(inputValue)
        if(enc === ans) {
          await updateCoupon()
          setReload(true)
          alert("兌換成功")
          location.push("/coupon/list?mode=redeem")
        } else {
          alert("兌換確認碼錯誤")
        }
      }}/>
      <div className="h-[20px]"></div>
      <Botton text="返回" onClick={()=> {
        location.push("/coupon/detail")
      }}/>
    </div>
  </div>
}

export default Redeem