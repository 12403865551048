'use client'

import React, {useState, useEffect} from "react";
import BrandComponent from "@/components/BrandComponent"
import { useSpring, animated } from 'react-spring';
import AnimatedButton from "@/components/AnimatedButton"
import { useRouter } from '@/hooks/useLocation';
import { easeCubicInOut } from 'd3-ease';
import FadedFragment  from "@/components/FadedFragment"
import { useGlobalContext } from '@/app/context/store';

const useSerialAnimation = () => {
	const textLine1 = useSpring({
		from: { opacity: 0, transform: "translateY(1em)" },
		to: { opacity: 1, transform: "translateY(0)" },
		delay: 400 + 400,
		config: { duration: 400, easing: easeCubicInOut }
	});

	const textLine2 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: 400 * 2 + 400,
			config: { duration: 400, easing: easeCubicInOut }
	});

	const textLine3 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: 400 * 3 + 400,
			config: { duration: 400, easing: easeCubicInOut }
	});

	const textLine4 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: 400 * 4 + 400,
			config: { duration: 400, easing: easeCubicInOut }
	});

	const textLine5 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: 400 * 5 + 400,
			config: { duration: 400, easing: easeCubicInOut }
	});

	const textLine6 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: 400 * 6 + 400,
			config: { duration: 400, easing: easeCubicInOut }
	});

	const textLine7 = useSpring({
		from: { opacity: 0, transform: "translateY(1em)" },
		to: { opacity: 1, transform: "translateY(0)" },
		delay: 400 * 7 + 400,
		config: { duration: 400, easing: easeCubicInOut }
});

const textLine8 = useSpring({
		from: { opacity: 0, transform: "translateY(1em)" },
		to: { opacity: 1, transform: "translateY(0)" },
		delay: 400 * 8 + 400,
		config: { duration: 400, easing: easeCubicInOut }
});

const textLine9 = useSpring({
		from: { opacity: 0, transform: "translateY(1em)" },
		to: { opacity: 1, transform: "translateY(0)" },
		delay: 400 * 9 + 400,
		config: { duration: 400, easing: easeCubicInOut }
});

const textLine10 = useSpring({
		from: { opacity: 0, transform: "translateY(1em)" },
		to: { opacity: 1, transform: "translateY(0)" },
		delay: 400 * 10 + 400,
		config: { duration: 400, easing: easeCubicInOut }
});

const textLine11 = useSpring({
		from: { opacity: 0, transform: "translateY(1em)" },
		to: { opacity: 1, transform: "translateY(0)" },
		delay: 400 * 11 + 400,
		config: { duration: 400, easing: easeCubicInOut }
});

	return [textLine1, textLine2, textLine3, textLine4, textLine5, textLine6, textLine7, textLine8, textLine9, textLine10, textLine11]
}

const textStyle = {
  color: '#F92C16',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '160%', 
  letterSpacing: '1px'
};


function StoryComponent() {
	const router = useRouter();
	const { setBgPlaying, stage, firstSelected, secondSelected, thirdSelected} = useGlobalContext()
  useEffect(() => {
    setBgPlaying(true)
  }, [])
	const animations = useSerialAnimation()
  const [fading, setFading] = useState(false);

  return (
    <div className="flex flex-col w-100dvw h-full pt-5 ">
      <BrandComponent active={stage}/>
      <FadedFragment
					onNextPage={() => router.push('/pages/end')}
					fading={fading}
					className="flex flex-col justify-between items-center pb-[50px] w-100dvw h-full"
				>
					<div></div>
					<div>
						<div className="flex flex-col items-center">
							<animated.div style={animations[0]}>時空</animated.div>
							<animated.div style={{...textStyle, ...animations[1]}} className="pb-[20px]">{"△"+firstSelected.title}</animated.div>
							<animated.div style={animations[2]}>角色</animated.div>
							<animated.div style={{...textStyle, ...animations[3]}} className="pb-[20px]">{"△"+secondSelected.title}</animated.div>
							<animated.div style={animations[4]}>事件</animated.div>
							<animated.div style={{...textStyle, ...animations[5]}} className="pb-[20px]">{"△"+thirdSelected.title}</animated.div>
							<animated.div style={animations[6]}>三大要素都齊全了</animated.div>
						</div>
						
					</div>
					<AnimatedButton text="來看看你的三段式腳本" showArrow onClick={() => setFading(true)}/>
				</FadedFragment>
		</div>
  );
}

export default StoryComponent;