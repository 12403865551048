import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PagesRouters } from "@/app/pages/layout"
import { PointsRouters } from "@/app/points/layout"
import { GlobalContextProvider } from '@/app/context/store';
import { AudioProvider } from "@/app/context/audioStore"
import { CouponRouters } from "@/app/coupon/layout"
import ReactGA from "react-ga4";

import './App.css';

if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

function App() {
  return (
    <div className='h-full'>
      <Router>
        <GlobalContextProvider>
          <AudioProvider>
            <Switch>
              <Route exact path="/pages/points" component={PointsRouters} />
              <Route path="/pages" component={PagesRouters} />
              <Route path="/coupon" component={CouponRouters} />
              {/* 若有其他路由可以在此加入 */}
              {/* <Route exact path="/">
                <Redirect to="/pages/loading" />
              </Route> */}
            </Switch>
          </AudioProvider>
        </GlobalContextProvider>
      </Router>
    </div>
  );
}


export default App;
