import Button from "./Button"
import { TCoupon } from "./types"
import { useRouter } from '@/hooks/useLocation';
import { format } from 'fecha';

const textStyle = {
  fontFamily: 'Inter',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '20px',
};

const titleStyle = {
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: 800,
  lineHeight: '26px',
  letterSpacing: '1px',
  // textAlign: 'center'
};



const dlStyle = {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '18px',
  // textAlign: 'center'
};



const InfoSouvenir = () => <div>
  ◆ 活動名稱：金馬60 - 致電影聲音故事互動<br /><br />
  ◆ 活動時間：<br />2023/11/11 ( 六 ) 11:00 -<br /> 2023/11/26 ( 日 ) 22:00<br /><br />
  ◆ 活動規則：參加活動之用戶於活動期間內，加入台北金馬影展 LINE 官方帳號 ( 下稱主辦單位 )，並完成「金馬 60 - 致電影聲音故事互動」以及「Golden Flow 展場限定！彩蛋採集」兩項活動，即有機會獲得指定活動禮品。活動期間內，每日前 250 名完成體驗並至服務台領取禮品者，即可獲得好禮二選一。禮品每日數量有限，若提前派發完畢、兌換完畢，將於主辦單位官方社群平台公告。禮品不得跨日領取，兌換資格限當日有效。<br /><br />
  ◆ 活動獎項：金馬 60 紀念品「W 春池計畫｜金馬六十紀念幣」乙個或「Let’s Café x 金馬聯名 MINICUP」乙顆，每日數量有限，換完為止。<br /><br />
  ◆ 兌獎方式及活動注意事項：
</div>

const InfoMoney = () => <div>
  ◆ 活動名稱：金馬60－致電影聲音故事互動<br /><br />
  ◆ 活動時間：2023/10/29  ( 日 ) 00:00 - 2023/11/23 ( 四 ) 23:59<br /><br />
  ◆ 活動規則：參加活動之用戶於活動期間內，加入台北金馬影展 LINE 官方帳號 ( 下稱主辦單位 ) 成為 LINE 好友，即可獲得指定活動獎項；活動期間內，活動獎項若提前派發完畢、兌換完畢，主辦單位不另行通知與公告。<br /><br />
  ◆ 活動獎項：「金馬 60 周邊商品 50 元折價券」乙份。 <br /><br />
  ◆ 兌獎方式及活動注意事項：
</div>

const DetailSouvenir = () => {
  return <>
    <ListItem content="本活動每個帳號於 LINE 官方帳號僅有 1 次獲獎機會。中獎將以 LINE 發送「金馬 60 周邊商品 50 元折價券」兌換連結，如因手機號碼不正確或不完整，導致獎券無法發送則視同放棄獎品。若因用戶網路狀況、裝置差異、手機設定、移轉帳號或其他變更等不可預測之因素導致活動無法順利完成或獲得之獎項遺失，主辦單位將不負擔任何責任或損害賠償。" />
    <ListItem content="「W 春池計畫｜金馬六十紀念幣」每日限兌換 160 個，「Let’s Café x 金馬聯名 MINICUP」每日限兌換 60 個。" />
    <ListItem content={
      <>
        「金馬 60 紀念品」兌換說明：<br />
        ※ 憑本兌換券可至信義區香堤大道金馬 60 展覽服務台兌換「金馬 60 紀念品」乙份。<br />
        ※ 每個 LINE 帳號只限兌換一次。<br />
        ※ 每日兌換名額為 220 個，獎項若派發完畢，主辦單位將公告於官方社群平台。<br />
        ※ 兌獎資格當日有效，不得跨日領取。
      </>
    } />
    <ListItem content="主辦單位保有參加者資格審核權，於任何時間可針對參加者之資格進行確認( 包括參加者身分、年紀、是否以惡意之電腦程式或不正當手段違反活動公平之方式，混淆或影響活動結果等 )，以確認參加者符合本條款之中獎資格。本活動之相關條件與參加者之資格，如有任何爭議者，主辦單位保有隨時及最終認定及解釋之權利。" />
    <ListItem content="本活動之所有獎品不得轉換、轉讓及折換現金，如遇不可抗力之因素，主辦單位保留隨時取消、更換其他等值獎項之權利，且無需事前通知或公布。獎品寄送過程中，因不可歸責於主辦單位之事由造成損壞、延遲，錯遞或遺失，主辦單位概不負責。中獎資格不得轉讓他人，中獎者不得要求更換獎項。" />
    <ListItem content="活動參加者於參加本活動之同時，即同意接受本活動所有內容及細則之規範，如有違反本活動注意事項之行為，主辦單位得隨時取消其中獎資格，並對於任何破壞本活動之行為保留法律追訴權。" />
    <ListItem content="如有任何因郵遞、電腦、網路、電話、技術或不可歸責於主辦單位之事由，而使參加及中獎者所登錄之資料有延遲、遺失、錯誤、無法辨識或毀損之情況，主辦單位不負任何法律責任，參加者亦不得因此異議。" />
    <ListItem content="本活動主辦單位為台北金馬影展執行委員會。主辦單位在法律許可範圍下及任何時間內有保留取消、變更、調整或解釋活動內容或變更或延後期間之權利。" />
    <ListItem content="參加活動過程中因天災或其他不可抗力所致之服務停止或中斷，主辦單位不負責任何法律責任，參加者不得因此異議。以任何駭客或其它非法行為破壞活動規則者，中獎無效，主辦單位並對有關參加者或中獎者保留法律追訴權。" />
    <ListItem content="參加者須自行負擔因參加本活動所發生之一切費用，包括但不限於郵資及可能之稅負，且不因主辦單位依據本辦法取消或變更活動內容而有不同，參加者無論如何均應自行負擔費用參加本活動。" />
    <ListItem content="主辦單位對於此活動或使用獎項時所造成的任何損失、傷害、個人意外或死亡概不負責。" />
    <ListItem content="若本條款之部分內容被認定為無效或不可執行者、其餘條款之效力及可執行性不受影響。" />
    <ListItem content="上述條款之準據法為中華民國法律，若因本活動而發生任何爭議時，應以台灣台北地方法院為第一審管轄法院。" />
  </>
}

const DetailMoney = () => {
  return <>
    <ListItem content="本活動每個帳號於 LINE 官方帳號僅有 1 次獲獎機會。中獎將以 LINE 發送「金馬 60 周邊商品 50 元折價券」兌換連結，如因手機號碼不正確或不完整，導致獎券無法發送則視同放棄獎品。若因用戶網路狀況、裝置差異、手機設定、移轉帳號或其他變更等不可預測之因素導致活動無法順利完成或獲得之獎項遺失，主辦單位將不負擔任何責任或損害賠償。" />
    <ListItem content={
      <>
        金馬 60 周邊商品 50 元折價券」兌換說明：<br />
        ※ 憑本折價券可至金馬展前服務台、金馬影迷補給站購買周邊商品單筆消費滿 200 元即可使用。<br />
        ※ 每個帳號單筆限用一次，海報、專刊及印刷品恕不適用。
      </>
    } />
    <ListItem content="主辦單位保有參加者資格審核權，於任何時間可針對參加者之資格進行確認( 包括參加者身分、年紀、是否以惡意之電腦程式或不正當手段違反活動公平之方式，混淆或影響活動結果等 )，以確認參加者符合本條款之中獎資格。本活動之相關條件與參加者之資格，如有任何爭議者，主辦單位保有隨時及最終認定及解釋之權利。" />
    <ListItem content="本活動之所有獎品不得轉換、轉讓及折換現金，如遇不可抗力之因素，主辦單位保留隨時取消、更換其他等值獎項之權利，且無需事前通知或公布。獎品寄送過程中，因不可歸責於主辦單位之事由造成損壞、延遲，錯遞或遺失，主辦單位概不負責。中獎資格不得轉讓他人，中獎者不得要求更換獎項。" />
    <ListItem content="活動參加者於參加本活動之同時，即同意接受本活動所有內容及細則之規範，如有違反本活動注意事項之行為，主辦單位得隨時取消其中獎資格，並對於任何破壞本活動之行為保留法律追訴權。" />
    <ListItem content="如有任何因郵遞、電腦、網路、電話、技術或不可歸責於主辦單位之事由，而使參加及中獎者所登錄之資料有延遲、遺失、錯誤、無法辨識或毀損之情況，主辦單位不負任何法律責任，參加者亦不得因此異議。" />
    <ListItem content="本活動主辦單位為台北金馬影展執行委員會。主辦單位在法律許可範圍下及任何時間內有保留取消、變更、調整或解釋活動內容或變更或延後期間之權利。" />
    <ListItem content="參加活動過程中因天災或其他不可抗力所致之服務停止或中斷，主辦單位不負責任何法律責任，參加者不得因此異議。以任何駭客或其它非法行為破壞活動規則者，中獎無效，主辦單位並對有關參加者或中獎者保留法律追訴權。" />
    <ListItem content="參加者須自行負擔因參加本活動所發生之一切費用，包括但不限於郵資及可能之稅負，且不因主辦單位依據本辦法取消或變更活動內容而有不同，參加者無論如何均應自行負擔費用參加本活動。" />
    <ListItem content="主辦單位對於此活動或使用獎項時所造成的任何損失、傷害、個人意外或死亡概不負責。" />
    <ListItem content="若本條款之部分內容被認定為無效或不可執行者、其餘條款之效力及可執行性不受影響。" />
    <ListItem content="上述條款之準據法為中華民國法律，若因本活動而發生任何爭議時，應以台灣台北地方法院為第一審管轄法院。" />
  </>
}

function ListItem({ content }: { content: any }) {
  return <div className="number-item">
    {content}
  </div>;
}


const CouponDetail = ({ coupon }: { coupon: TCoupon | null }) => {
  const location = useRouter()
  if (coupon === null) return null
  const { title, expire, imgSrc } = coupon

  const taipeiDate = new Date().toLocaleString('en-US', { timeZone: 'Asia/Taipei' });
  const isExpired = expire < format(new Date(taipeiDate), 'YYYY/MM/DD');

  return <div className="bg-black w-full flex flex-col items-center pt-[40px] pb-8">
    <img className="absolute top-[0px]" src="/images/coupon-background.png" alt="coupon-backgroud" />
    <div className="flex z-10 flex-row justify-between items-end w-full px-3 pb-8">
    </div>
    <img
      src={imgSrc}
      width={264}
      alt="photo_gift1"
      className="pb-[16px]"
    />
    <div style={titleStyle}>
      {title}
    </div>
    <div className="py-2" style={dlStyle}>
      有效日期：{expire}
    </div>
    <div className=" w-full px-[40px]">
      <Button text={isExpired ? '已超出有效日期，無法兌換' : "請交由現場工作人員點擊兌換"} onClick={() => {
        if (isExpired) return;
        location.push("/coupon/redeem")
      }} />
    </div>
    {coupon.title.includes("折價") && <>
      <div className="pt-9 px-10" style={textStyle}>
        <InfoMoney />
      </div>
      <div className="list-container w-full px-[40px] pb-[60px]" style={textStyle}>
        <DetailMoney />
      </div>
    </>}
    {coupon.title.includes("紀念品") && <>
      <div className="pt-9 px-10" style={textStyle}>
        <InfoSouvenir />
      </div>
      <div className="list-container w-full px-[40px] pb-[60px]" style={textStyle}>
        <DetailSouvenir />
      </div>
    </>}
    <div className=" w-full px-[40px]">
      <Button text="返回" onClick={() => location.push("/coupon/list")} />
    </div>


  </div>
}

export default CouponDetail