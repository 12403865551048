'use client'

import React, {useEffect, useState} from "react";
import Image from "@/components/Image"

import AnimatedButton from "@/components/AnimatedButton"
import { useSpring, animated } from 'react-spring';
import { easeCubicInOut } from 'd3-ease';
import { innerTextStyle } from "@/common/fontStyle"
import { useRouter } from '@/hooks/useLocation';
import FadedFragment  from "@/components/FadedFragment"
import useAudioPlayer from "@/hooks/useAudioPlayer";
import { trackEvent } from "@/common/gaUtil";

const useSerialAnimation = () => {
	const textLine1 = useSpring({
		from: { opacity: 0, transform: "translateY(1em)" },
		to: { opacity: 1, transform: "translateY(0)" },
		delay: 400 + 400,
		config: { duration: 400, easing: easeCubicInOut }
	});

	const textLine2 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: 400 * 2 + 400,
			config: { duration: 400, easing: easeCubicInOut }
	});

	const textLine3 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: 400 * 3 + 400,
			config: { duration: 400, easing: easeCubicInOut }
	});

	const textLine4 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: 400 * 4 + 400,
			config: { duration: 400, easing: easeCubicInOut }
	});

	const textLine5 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: 400 * 5 + 400,
			config: { duration: 400, easing: easeCubicInOut }
	});

	const textLine6 = useSpring({
			from: { opacity: 0, transform: "translateY(1em)" },
			to: { opacity: 1, transform: "translateY(0)" },
			delay: 400 * 6 + 400,
			config: { duration: 400, easing: easeCubicInOut }
	});

	const textLine7 = useSpring({
		from: { opacity: 0, transform: "translateY(1em)" },
		to: { opacity: 1, transform: "translateY(0)" },
		delay: 400 * 7 + 400,
		config: { duration: 400, easing: easeCubicInOut }
});

const textLine8 = useSpring({
		from: { opacity: 0, transform: "translateY(1em)" },
		to: { opacity: 1, transform: "translateY(0)" },
		delay: 400 * 8 + 400,
		config: { duration: 400, easing: easeCubicInOut }
});

const textLine9 = useSpring({
		from: { opacity: 0, transform: "translateY(1em)" },
		to: { opacity: 1, transform: "translateY(0)" },
		delay: 400 * 9 + 400,
		config: { duration: 400, easing: easeCubicInOut }
});

const textLine10 = useSpring({
		from: { opacity: 0, transform: "translateY(1em)" },
		to: { opacity: 1, transform: "translateY(0)" },
		delay: 400 * 10 + 400,
		config: { duration: 400, easing: easeCubicInOut }
});

const textLine11 = useSpring({
		from: { opacity: 0, transform: "translateY(1em)" },
		to: { opacity: 1, transform: "translateY(0)" },
		delay: 400 * 11 + 400,
		config: { duration: 400, easing: easeCubicInOut }
});

	return [textLine1, textLine2, textLine3, textLine4, textLine5, textLine6, textLine7, textLine8, textLine9, textLine10, textLine11]
}

const textLines = [
  "是構成電影的三大要素",
	"",
	"20 位金馬影人",
	"將為你獻聲朗讀電影片段",
	"請選擇有感的人事時地",
	"與他們共譜一篇金馬腳本",
	"",
	"開始之前",
	"有請金馬 60 的主席",
	"—— 資深攝影師李屏賓 ——",
	"為電影揭開序幕"
];

function StoryComponent() {
  const audio = useAudioPlayer(
		{
		 src: "/audio/賓哥_MIX.m4a",
		 onFinish: () =>{ 
      setFading(true)
     }
		}
	 )
	
  const [fading, setFading] = useState(false);
  const router = useRouter();

	useEffect(() => {
		trackEvent('Page_Welcome');
	},[]);

	const logoTitleAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 500, easing: easeCubicInOut },
  });

	const btnAnimations = useSpring({
    from: { opacity: 0, transform: "translateY(1em)" },
    to: { opacity: 1, transform: "translateY(0)" },
    delay: 5000, // 淡入间隔 0.4 秒
    config: { duration: 400, easing: easeCubicInOut }
  });

	const textAnimations = useSerialAnimation()

  return (
		<div  className="flex flex-col justify-between items-center w-100dvw h-full pb-10 pt-10">
			<animated.img
				style={logoTitleAnimation}
				loading="eager"
				layout="intrinsic"
				width={30}
				height={0}
				src="/images/logo2.png"
				alt=""
			/>
			<FadedFragment
				fading={fading}
				onNextPage={() => router.push('/pages/intro4')}
				className="flex-1 flex flex-col justify-between items-center w-100dvw pb-10 pt-10"
			>
				<div></div>
				<div>
					<animated.div className="flex flex-row gap-8 pb-2.5" style={logoTitleAnimation}>
						<Image
							loading="eager"
							layout="intrinsic"
							width={44}
							height={0}
							src="/images/intro3-title1.png"
							alt=""
						/>
						<Image
							loading="eager"
							layout="intrinsic"
							width={44}
							height={0}
							src="/images/intro3-title2.png"
							alt=""
						/>
						<Image
							loading="eager"
							layout="intrinsic"
							width={44}
							height={0}
							src="/images/intro3-title3.png"
							alt=""
						/>
					</animated.div>
					<div className="text-center" style={innerTextStyle}>
						{textLines.map((line, index) => (
							<animated.div key={index} style={{...textAnimations[index], color: "#FFF"}}>
								{line}
								<br />
							</animated.div>
						))}
					</div>
				</div>
				<AnimatedButton text="掌聲歡迎賓哥" style={btnAnimations} onClick={() => {
					trackEvent('Btn_Welcome');
					audio.play(900)
					setFading(true)
				}}/>
			</FadedFragment>
		</div>
  );
}

export default StoryComponent;