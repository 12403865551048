'use client'

import React from 'react';

export default function BackgroundAnimation({ children }) {
  return (
    <div className="relative w-full h-full container">
      {/* 基礎背景圖層 */}
      <div className="fixed top-0 left-0 w-full h-full">
        <div style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
          <img 
            src="/images/bg1.png" 
            alt="bg1" 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center'
            }}
          />
        </div>
      </div>

      {/* 動態效果的背景圖層 */}
      <div className="fixed top-0 left-0 w-full h-full opacity-100 animate-fadeInOut">
        <div style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
          <img 
            src="/images/bg2.png" 
            alt="bg2" 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center'
            }}
          />
        </div>
      </div>

      {/* 其他內容 */}
      <div className="relative z-10 w-full h-full">
        {children}
      </div>

      <style jsx>{`
        @keyframes fadeInOut {
          0% { opacity: 1; }
          50% { opacity: 0; }
          100% { opacity: 1; }
        }
        .animate-fadeInOut {
          animation: fadeInOut 8s infinite ease-in-out;
        }
      `}</style>
    </div>
  );
}
