'use client'

// AnimatedButton.tsx
import React from 'react';
import Image from "@/components/Image"

interface AnimatedButtonProps {
  text: string;
  onClick: () => void;
  showArrow?: boolean;
  style?: any;
}
const AnimatedButton: React.FC<AnimatedButtonProps> = ({ text, onClick, showArrow, style }) => {
  return (
    <div 
      className={`relative justify-center items-center border-[color:#F92C16] bg-[color:#F92C16] self-center flex w-[156x] h-10 max-w-full flex-row px-5 py-3.5 rounded-[50px] border-2 border-solid`}
      style={style}
      onMouseUp={onClick}
    >
      <p className={`text-white text-center text-base font-medium leading-6 tracking-wider self-center  no-select`}>
        {text}
      </p>
      {showArrow && (
        <div className="flex-shrink-0 ml-2" style={{ width: 8, height: 10 }}> {/* 添加外部容器 */}
          <Image src="/images/buttonArrow.png" width={8} height={10} alt="buttonArrow" layout="intrinsic"/>
        </div>
      )}
    </div>
  );
}

export default AnimatedButton;