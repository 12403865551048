import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Coupon from "./Coupon"
import { TCoupon } from "./types"
import CouponDetail from "./CouponDetail"
import Redeem from "./Redeem";
import { useGlobalContext } from "../context/store";
import { getUser } from "@common/firebaseDB"

const data: TCoupon[] = [
  {
    title: "金馬60紀念品",
    desc: "每日 220 名，換完為止，限定現場兌換",
    expire: "2023/11/23",
    redeem: "2023/11/23",
    imgSrc: "/images/photo_gift1.png"
  },
  {
    title: "金馬 60 周邊商品 50 元折價券",
    desc: "限定現場兌換",
    expire: "2023/11/23",
    redeem: "2023/11/23",
    imgSrc: "/images/photo_gift2.png"
  }
]



export const CouponRouters = () => {
  const [
    coupon,
    setCoupon
  ] = useState<TCoupon | null>({
    title: "金馬60紀念品",
    desc: "每日 220 名，換完為止，限定現場兌換",
    expire: "2023/11/23",
    redeem: "2023/11/23",
    imgSrc: "/images/photo_gift1.png"
  })

  const { userId } = useGlobalContext();
  const [couponList, setCouponList] = useState<TCoupon[]>([])
  const [reload, setReload] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    const loadData = async () => {
      if (userId == null) return
      setReload(false)
      // load data here
      const user = await getUser(userId)
      if (!user) {
        setLoading(false)
        return
      }
      const cardList = user.coupons.map<TCoupon>((item) => {
        const couponData = data.find((c) => c.title === item.title)
        return {
          title: couponData?.title ?? "",
          desc: couponData?.desc ?? "",
          expire: item?.expire ?? "",
          redeem: item?.redeem,
          imgSrc: couponData?.imgSrc ?? ""
        }
      })
      await setCouponList(cardList)
      setLoading(false)

    }
    if (reload && userId !== null) loadData()
  }, [reload, userId])


  return (
    <Switch>
      <Route path="/coupon/list">
        <Coupon setCoupon={setCoupon} couponList={couponList} loading={loading} />
      </Route>
      <Route path="/coupon/detail">
        <CouponDetail coupon={coupon} />
      </Route>
      <Route path="/coupon/redeem">
        <Redeem coupon={coupon} setReload={setReload} couponList={couponList} />
      </Route>
      {/* 若有其他子路由可以在此加入 */}
    </Switch>
  );
}