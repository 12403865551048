import Image from "@/components/Image"
import { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { getUser } from "@common/firebaseDB"
import { useGlobalContext } from "../context/store";
import { trackEvent } from "@/common/gaUtil";

interface ICard {
	own: boolean;
	url: string;
}
const originCards = [
	{
			"own": false,
			"url": "/images/photo_劉冠廷.png"
	},
	{
			"own": false,
			"url": "/images/photo_許光漢.png"
	},
	{
			"own": false,
			"url": "/images/photo_袁澧林.png"
	},
	{
			"own": false,
			"url": "/images/photo_柯震東.png"
	},
	{
			"own": false,
			"url": "/images/photo_林柏宏.png"
	},
	{
			"own": false,
			"url": "/images/photo_宋芸樺.png"
	},
	{
			"own": false,
			"url": "/images/photo_王渝萱.png"
	},
	{
			"own": false,
			"url": "/images/photo_王淨.png"
	}
]


function PointCard() {
	const { userId } = useGlobalContext()
	const [ cards, setCards ] = useState<ICard[]>(originCards)
	useEffect(() => {
		const getCards =  async () => {
			if(!userId) return
			const user = await getUser(userId)
			const points: any = user?.points??{}
			const userCards: ICard[] = [
				'劉冠廷','許光漢','袁澧林','柯震東','林柏宏','宋芸樺','王渝萱','王淨'
			].map(name => {
				return {
					own: points[name]??false as boolean,
					url: `/images/photo_${name}.png`
				}
			})
			setCards(userCards)			
		}
		getCards()
  }, [userId])


  return (
      <div style={{
				backgroundImage: "url(/images/point-border.png)",
				backgroundSize: "cover",
				width: "310px",
				height: "211px",
				padding: "16px 13px",
				marginBottom: "50px"
			}}>
				<div className="flex justify-between ">
					<div>Collect</div>
					<div>{cards.filter(c=>c.own).length}/8</div>
				</div>
				<div className="bg-white h-[1px]  mb-[21px]"/>
				<div className="grid grid-cols-4 gap-4">
				{
					cards.map(({own, url}, index) => {
						return <div key ={url} className="col-span-1 ">
							{
								own || <img
									key={index}
									src={"/images/point-placeholder.png"}
									alt={"/images/point-placeho"}
									width="60px"
									height="60px"
								/>
							}
							{
								own && <img
									key={index}
									src={url}
									alt={url}
									width="60px"
									height="60px"
								/>
							}
							
						</div>
					})
				}
				</div>
			</div>
  );
}

interface ImgButtonProps {
  text: string;
  onClick?: () => void;
  imgUrl?: string;
  style?: any;
}
const ImgButton = ({ text, onClick, imgUrl, style }: ImgButtonProps) => {
  return (
		<Link to={`/pages/points#${text}`}>{
			<div 
				className={`relative justify-center items-center border-[color:#F92C16] self-center flex min-w-[260px] h-10 max-w-full flex-row px-10 py-3.5 rounded-[50px] border-2 border-solid active:bg-gray-300}`}
				onMouseUp={onClick}
			>
				{imgUrl && (
					<div className="flex-shrink-0 mr-2" style={{ width: 22, height: 22 }}> {/* 添加外部容器 */}
						<Image src={imgUrl} width={22} height={22} alt="buttonArrow" layout="intrinsic"/>
					</div>
				)}
				<p className={`text-red-600 text-center text-base font-medium leading-6 tracking-wider self-center  no-select`}>
					{text}
				</p>
			</div>
		}</Link>
    
  );
}

const locationList = [
  <>閱讀完 GOLDEN FLOW<br/>金馬 60 互動展的介紹</>,
  "詢問看看金馬獎的服務人員吧",
  <>閱讀完聲音故事互動體驗的說明<br/>往下低頭看看</>,
  "閃爍燈光的盡頭",
  "踏進金馬獎輝煌的時刻",
  "回憶金馬獎在螢幕上的經典瞬間",
  "最多金馬60標誌的地方",
  "威士忌香醇可口"
]

const body_txt_style = {
  fontFamily: 'Inter',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '1px',
};


const red_txt_style = {
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '25.6px',
  letterSpacing: '1px',
	color: "#F92C16",
};

function StoryComponent() {
	const location = useLocation();

	useEffect(() => {
		trackEvent('Page_Easter_Egg');
	},[])

  useEffect(() => {
    if (location.hash) {
      const elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);


  return (
      <div className="flex flex-col items-center py-8">
    		<img className="absolute top-[0px]" src="/images/coupon-background.png" alt="coupon-backgroud" />
				<Image
					loading="eager"
					layout="intrinsic"
					width={48}
					height={0}
					src="/images/logo2.png"
					alt=""
					className="mb-8 z-10"
				/>
				<div className="text-center" style={body_txt_style}>
					在展區範圍內<br/>
					仔細找找特別的票根<br/>
					收藏影人們留下的特別彩蛋吧！
				</div>
				<Image
					loading="eager"
					layout="intrinsic"
					width={88}
					height={0}
					src="/images/title_彩蛋紀錄.png"
					alt=""
					className="mt-[30px] mb-5"
				/>
				<PointCard/>
				<ImgButton text="如何採集" imgUrl="/images/question-circle.png"/>
				<div className="pb-5"></div>
				<ImgButton text="彩蛋線索" imgUrl="/images/finder.png"/>
				<div id="如何採集">
					<Image
						loading="eager"
						layout="intrinsic"
						width={88}
						height={0}
						src="/images/title_如何採集.png"
						alt=""
						className="mt-50 mb-3"
					/>
				</div>
				<div className="flex flex-row items-center justify-center pb-10px">
					<Image
						loading="eager"
						layout="intrinsic"
						width={88}
						height={0}
						src="/images/slim-arrow-left.png"
						alt=""
					/>
					<div style={red_txt_style}>
					STEP 1
					</div>
					<Image
						loading="eager"
						layout="intrinsic"
						width={88}
						height={0}
						src="/images/slim-arrow-right.png"
						alt=""
					/>

				</div>
				<Image
					loading="eager"
					layout="intrinsic"
					width={295}
					src="/images/collect_step1.jpg"
					alt=""
				/>
				<div className="pt-10px">
				在本次展覽範圍內，尋找特殊票根
				</div>
				<div className="flex flex-row items-center justify-center pt-[30px] pb-[10px]">
					<Image
						loading="eager"
						layout="intrinsic"
						width={88}
						height={0}
						src="/images/slim-arrow-left.png"
						alt=""
					/>
					<div style={red_txt_style}>
					STEP 2
					</div>
					<Image
						loading="eager"
						layout="intrinsic"
						width={88}
						height={0}
						src="/images/slim-arrow-right.png"
						alt=""
					/>
				</div>
				<Image
					loading="eager"
					layout="intrinsic"
					width={295}
					src="/images/collect_step2.jpg"
					alt=""
				/>
				<div className="mb-5 mt-[10px]">掃描票根上的QR code 後，送出訊息</div>
				<div id="彩蛋線索">
					<Image
						loading="eager"
						layout="intrinsic"
						width={88}
						height={0}
						src="/images/title_彩蛋線索.png"
						alt=""
						className="mt-50 mb-4"
					/>
				</div>
				{locationList.map((location) => {
					return <div className="flex flex-col justify-center items-center mb-[30px] text-center">
					<Image
						loading="eager"
						layout="intrinsic"
						width={17}
						height={0}
						src="/images/location.png"
						alt=""
					/>
					<Image
						loading="eager"
						layout="intrinsic"
						width={280}
						height={0}
						style={{
							marginTop: "10px",
							marginBottom: "6px",
						}}
						src="/images/slim-line.png"
						alt=""
					/>
						<div>{location}</div>
					</div>
				})}
				<Image
					loading="eager"
					layout="intrinsic"
					width={48}
					height={0}
					src="/images/logo.png"
					alt=""
					className="mt-50"
				/>
      </div>
  );
}

export default StoryComponent;